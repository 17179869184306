import DATA from './cards'
const state = {
  // URL:"https://fuseg-uat-app-ecb7i.ondigitalocean.app",
  URL:"https://fuse.gold",
  // URL:"http://localhost:3030",
  isLoading:false,
  modalRegister:false,
  modalReferral:false,
  modalID:false,
  modalNick:false,
  prom:true,
  routeLoader:false,
  promUsers:[],
  leaderboard:[],
  totalPointsSum:0,
  rewardConfig:null,
  searchedUser:null,
  AppData:null,
  paymentSellSearch:'',
  web3:null,
  LDATA:null,
  mainConfig:{},
  settings:{},
  userType:null,
  user: null,
  provider:null,
  userWallet:null,
  allUsers:[],
  action:"signin",
  card: DATA.card1,
  card2: DATA.card2,
  records: DATA.records,
  recordsMarchant: DATA.recordsMarchant,
  marchantTrx: DATA.marchantTrx,
  wpCards: DATA.wpCards,
  scCards: DATA.scCards,
  scInfoCard: DATA.scInfoCard,
  scInfoCardsTwo: DATA.scInfoCardsTwo,
  //end custom states
  refCount:{},totalReferred:0,refCountVerified:{},totalReferredVerified:0,promUsers:[],
  dark: (localStorage.getItem('fuseGoldDarkMode') ) ? JSON.parse(localStorage.getItem('fuseGoldDarkMode')) : false
}

export default state;