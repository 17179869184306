<template>
  <div class='row justify-content-end m-0'>
    
  <div class="d-lg-none">
    <div class="col-xl-11 col-12 p-0 " >
      <b-navbar toggleable="lg" type="dark" fixed="top"  class="px-0 mx-0 clLight" style="z-index:999999;">
    <b-navbar-brand @click='goTO("/")' class='text-dark mw-200' > 
    <div class="d-none d-lg-block">
<img @click='goTO("/")' v-if='$store.state.dark' class="mw-100" src="@/assets/images/logo-dark.png"  alt="">
        <img @click='goTO("/")' v-else src="@/assets/images/logo-light.png" class="mw-100" alt="">
    </div>
    <div class="d-lg-none"
    style="    width: 208px;padding-left: 20px;padding-top: 4px;"
    >
<img @click='goTO("/")' v-if='$store.state.dark' class="mw-100 logo-mobile" src="@/assets/images/logo-dark.png"  alt="">
        <img @click='goTO("/")' v-else src="@/assets/images/logo-light.png" class="mw-100 logo-mobile" alt="">
    </div>
        
    </b-navbar-brand>
<div class="opNinteen d-lg-none" style="    position: absolute;
    top: 0vw;
    right: 13vw;">
         <img src="/light switch off.png" @click='$store.state.dark = false;upDark(false);' v-if='$store.state.dark' class='click mw-80px' alt="">
         <img src="/light switch on.png" @click='$store.state.dark = true;upDark(true);' v-else class='click mw-80px' alt="">
        </div>
    <b-navbar-toggle target="nav-collapse" type='light'>
      
        <img class="mw-100" src="@/assets/images/ham.png" style="    margin-right: 41px;width: 40px;"  alt="">
      
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto pt-4" >
        <b-nav-item class='n-item' v-if='!user'   @click='$store.state.action = "signup"; $store.state.modalRegister = true;' right>
        <p class="desc-one-link">register now</p>
        </b-nav-item>
        <!-- <b-nav-item class="n-item" right> <p class="desc-one-link" @click='goTO("/partners")'>Partners</p> </b-nav-item> -->
        <b-nav-item class="n-item" right> <p class="desc-one-link" @click='goTO("/leaderboard")'>Leaderboard</p> </b-nav-item>
        <b-nav-item class="n-item" right> <p class="desc-one-link" @click='goTO("/security-custody")'>SECURITY & CUSTODY</p> </b-nav-item>
        <b-nav-item class="n-item" right> <p class="desc-one-link" @click='goTO("/key-features")'>KEY FEATURES</p> </b-nav-item>
        
        <b-nav-item>
          <button-shimmer-nav></button-shimmer-nav>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
  </div>
  <div class="col-xl-11 col-12 p-0" >
      <b-navbar toggleable="lg" type="dark"  class="px-0 mx-0">
    <b-navbar-brand @click='goTO("/")' class='text-dark mw-200' > 
    <div class="d-none d-lg-block">
<img @click='goTO("/")' v-if='$store.state.dark' class="mw-100" src="@/assets/images/logo-dark.png"  alt="">
        <img @click='goTO("/")' v-else src="@/assets/images/logo-light.png" class="mw-100" alt="">
    </div>
    <div class="d-lg-none"
    style="    width: 208px;padding-left: 20px;padding-top: 4px;"
    >
<img @click='goTO("/")' v-if='$store.state.dark' class="mw-100 logo-mobile" src="@/assets/images/logo-dark.png"  alt="">
        <img @click='goTO("/")' v-else src="@/assets/images/logo-light.png" class="mw-100 logo-mobile" alt="">
    </div>
        
    </b-navbar-brand>
<div class="opNinteen d-lg-none" style="    position: absolute;
    top: 0vw;
    right: 13vw;">
         <img src="/light switch off.png" @click='$store.state.dark = false;upDark(false);' v-if='$store.state.dark' class='click mw-80px' alt="">
         <img src="/light switch on.png" @click='$store.state.dark = true;upDark(true);' v-else class='click mw-80px' alt="">
        </div>
    <b-navbar-toggle target="nav-collapse" type='light'>
      
        <img class="mw-100" src="@/assets/images/ham.png" style="    margin-right: 41px;width: 40px;"  alt="">
      
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto pt-4" >
        <b-nav-item class='n-item' v-if='!user'   @click='$store.state.action = "signup";$store.state.modalRegister = true;' right>
        <p class="desc-one-link">register now</p>
        </b-nav-item>
        <b-nav-item class="n-item" :class="{selected:$route.path == '/leaderboard'}" right> <p class="desc-one-link" @click='goTO("/leaderboard")'>Leaderboard</p> </b-nav-item>
        <!-- <b-nav-item class="n-item" :class="{selected:$route.path == '/mint'}" right> <p class="desc-one-link" @click='goTO("/mint")'>Mint</p> </b-nav-item> -->

        <!-- <b-nav-item class="n-item" right> <p class="desc-one-link" @click='goTO("/partners")'>Partners</p> </b-nav-item> -->
        <b-nav-item class="n-item" :class="{selected:$route.path == '/security-custody'}" right> <p class="desc-one-link" @click='goTO("/security-custody")'>SECURITY & CUSTODY</p> </b-nav-item>
        <b-nav-item class="n-item" :class="{selected:$route.path == '/key-features'}" right> <p class="desc-one-link" @click='goTO("/key-features")'>KEY FEATURES</p> </b-nav-item>
        
        <b-nav-item>
          <div class="opNinteen d-none d-lg-block" style="
              position: absolute;
    top: -20px;
    right: 59px;">
         <img src="/light switch off.png" @click='$store.state.dark = false;upDark(false);' v-if='$store.state.dark' class='click mw-80px' alt="">
         <img src="/light switch on.png" @click='$store.state.dark = true;upDark(true);' v-else class='click mw-80px' alt="">
        </div>
          <button-shimmer-nav></button-shimmer-nav>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
</div>
</template>

<script>
import {VBModal, BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav,BNavItem, BCollapse } from 'bootstrap-vue'
// Vue.component('b-navbar', BNavbar)
import {mapState} from 'vuex';
export default {
  props:{
    wallet:{
      Type: Boolean,
      default: true
    },
    address:{
      type: String,
      default: null
    }
  },
  data(){
    return {
      blc:100,
      provider:null,
    }
  },
   components: {
    BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav,BNavItem, BCollapse,
    ButtonShimmerNav:() => import('@/components/ButtonShimmerNav.vue'),
    },
  computed:{
    ...mapState(['userType','user','userWallet','dark','ABI','ADDRESS']),
    formattedUserWallet(){
      return this.userWallet.substr(0, 6)+'....'+this.userWallet.substr(this.userWallet.length-4, 4);
    },
    compPath() {
      let path = null;
      if(this.user.permissions.admin || this.user.permissions.super) path = "/dashboard/admin"  
      else if (this.user.permissions.kyc) path = "/dashboard/kyc"
      else if (this.user.permissions.merchant) path = "/dashboard/merchant"
      else if (this.user.permissions.client) path = "/dashboard/client"
      return path;
    }
  },
  watch:{
    // dark(value) {
      
    // }
  },
  async mounted(){
            // await this.$store.dispatch('getSettings', {});
  },
  methods:{
    upDark(value){
      console.log('dark value changed', value)
      localStorage.setItem('fuseGoldDarkMode' ,JSON.stringify(value))
    },
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push({path: "/"})
    },
    goTO(path) {
      this.$router.push({path})
    },
    
    
   
    
 

  }
}
</script>

<style>
.y-border{
    border: 4px solid red;
    width:100px;
    height: 2px;
}
.nav-link{
    text-transform: uppercase;
    color:#25282d !important;
    cursor: pointer;
    font-weight: 600;
}
.n-item{
  margin-top: 13px;
}
</style>