
import {
VToken,searchRank,getleaderboardData,getCachedData,saveCachedData,
  verifyEmailProm,
getPromUsersPro,
getRewardConfig,
getPromUsers,
updateUsernames,
sendQrCode,
getNickName,
sendVerifEmail,
checkReferralID,
checkReferral,
sendMail,
addRegInterest







} from './backend'

export default { 
  
  checkReferralID,
  checkReferral,
  sendVerifEmail,
  getNickName,
  updateUsernames,
  getRewardConfig,
  sendQrCode,
  getPromUsersPro,getPromUsers,
  addRegInterest,
VToken,searchRank,getleaderboardData,getCachedData,saveCachedData,
  verifyEmailProm,
  
  sendMail,
};