<template>
  <div>
    <div class="d-lg-none">
<div class="d-block mx-auto mb-4 my-5 btn-shim">
    <button  class=" button-shape d-block mx-auto parallelogram position-relative" style="height:46px;">
      <span class="skew-fix"> <b>REGISTER HERE</b>  </span>
    </button>
    <button @click="$store.state.modalRegister = true" class=" button-shape-cont d-block mx-auto parallelogram position-relative" style="height:46px;">
      <span class="skew-fix"> <b>REGISTER HERE</b>  </span> <i></i>
    </button>
  </div>
    </div>
    <div class="d-none d-lg-block my-5" style="
        transform: scale(1.5);
    width: 55%;
    margin-left: 30px;">
      <div class="d-block btn-shim">
    <button  class=" button-shape d-block mx-auto parallelogram position-relative" style="height:46px;">
      <span class="skew-fix"> <b>REGISTER HERE</b>  </span>
    </button>
    <button @click="$store.state.modalRegister = true" class=" button-shape-cont d-block mx-auto parallelogram position-relative" style="height:46px;">
      <span class="skew-fix"> <b>REGISTER HERE</b>  </span> <i></i>
    </button>
  </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.button-shape:disabled {
  background: #c7c7c7 !important;
  color:rgb(60, 60, 60) !important;
}
.button-shape {
  font-family: "Calibri" !important;
  background: #b99653;
  color: rgb(234, 234, 234);
    border: 1px solid #b99653;
    letter-spacing: 2px;
  box-shadow: 0px 13px 19.53px 1.47px rgb(0 0 0 / 13%);

    border-radius: 7px 6px 7px 7px;
    font-size: 1.1rem;
    padding: .1rem 2.4rem .1rem 2.4rem;
}
/* .button-shape-cont:hover{
} */
.button-shape-cont {
  box-shadow: 24px 10px 21px 0px rgb(0 0 0 / 35%);
  font-family: "Calibri" !important;
  border: 1px solid transparent;
  color: transparent;
  letter-spacing: 2px;
  border-radius: 7px 6px 7px 7px;
  font-size: 1.1rem;
  margin-top: -46px;
  background: transparent;
  padding: 0.1rem 2.4rem 0.1rem 2.4rem;
}


.parallelogram{
  transform: skew(-40deg);
}

.skew-fix{
  display:inline-block;
  transform: skew(40deg);
}

  .button-shape-cont i {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
  width: 20%;
  height: 100%;
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
  }
  
  @keyframes move {
    0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 83%}
    }
  
  @-webkit-keyframes move {
0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-moz-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-ms-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-o-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  /* @media (max-width: 575.98px) { */
    .button-shape-cont, .button-shape{
      min-width: 320px !important;
    }
  /* } */
</style>